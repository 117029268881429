import React from "react"
import { Link } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import title_image from '../../../images/titles/business_process_measurement.jpg'
import performance_management from '../../../images/pages/performance_management.png'
import person from '../../../images/person.png'

const pageTitle = "Performance Measurement";
const pageSLug = "performance-measurement";
class ProcessCapability extends React.Component{
  render(){
    return(
      <Layout pageType="business-consulting">
      <SEO title={pageTitle} />
      <section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">Performance measurements are the vital signs of your organization. What gets measured gets done!</h6>
        </div>
      </section>

      <section id="p_business_consulting" className="section static-page page-content">
        <div className="container">
            <div className="columns">
                <div className="column is-12 content-area">
                    <h3>An Overview of Performance Measurement.<br />
                    The purpose of Performance Measurement is to measure, communicate, manage, and improve business performance.
                    </h3>
                    <p>Neurex's Performance Measurement services help organizations align their operational activities with 
                    strategic business priorities. Our experts use cutting edge tools to give you the best 
                    measurements possible.</p>
                    <h6>Performance Measurements allows your business to make the right decisions and answer 
                        questions like:</h6>
                    <ul>
                        <li>Are we measuring the right things?</li>
                        <li>Are our measurements proactive?</li>
                        <li>Are our processes being improved?</li>
                    </ul>
                    <span className="break"></span>
                    <h4>Performance Measurement Services</h4>
                    <p>Our performance Measurement services provide clients with specific solutions. 
                        Learn more about each capability in our Performance Measurement services.</p>
                        <span className="break"></span>
                    <h4>Closed-Loop Performance Measurement</h4>
                    <p>A closed-loop performance measurement process turns measurements into actions.
                         Providing a solution that can guide your decision-making process.</p>
                         <span className="break"></span>
                    <h4>Aligning Business Strategy with Value Drivers</h4>
                    <p>An effective performance measurement plan will make strategy and vision the 
                        central focus of your organization. At Nuerex, we help you align your 
                        strategic goals with essential value drivers.</p>
                        <span className="break"></span>
                    <h4>Performance Measurement Culture</h4>
                    <p>Developing a culture that adheres to performance management philosophies is 
                        vital in ensuring quality. We can assist in integrating this change into 
                        your organization by providing practical solutions.</p>
                        <span className="break"></span>
                    <h4>Metric Scorecard & Report Development</h4>
                    <p>Measuring and assessing is a core process. We assist in designing reports 
                        and balanced scorecards that allow clear communication to any relevant stakeholders.</p>
                    <span className="break"></span>
                    <p><b>Implementing a thorough performance measurement program will allow your organization 
                        to create value in the quickest way possible by providing accurate predictions 
                        that are backed by real results.</b></p>
                    <img src={performance_management} />
                </div>
            {/*    <div className="column is-3 sidebar">
                    <div className="person-info">
                        <img src={person} />
                        <div className="inner">
                            <h2 className="name">Greg Klem</h2>
                            <p className="status">Vice President</p>
                            <p>National Practice Lead - Business </p>
                            <p>Consulting Services</p>
                        </div>
                        <p>Not sure where to start, our team is always ready to help.</p>
                        <Link to="/" className="button green">Talk with a professional</Link>
                    </div>
                </div>*/}
            </div>
        </div>
      </section>
      </Layout>
    )
  }
}

export default ProcessCapability
